<template>
  <section class="content">
    <div class="card">
        <div class="card-body">
            <div class="col-md-11">
                <div v-html="form.content"></div>
            </div>
        </div>
        <div class="card-footer">
            <!-- <router-link
            to="/mapel-materi"
            class="btn btn-secondary"
            >
            Kembali
            </router-link> -->
        </div>
    </div>
  </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    created: function () {
        if (this.$route.params.id) {
        this.loadContent(this.$route.params.id);
        } else {
            return false;
        }
    },
    data: () => ({
        form: {
            content: "",
        },
    }),
    methods: {
    handleClick(e) {},
    loadContent(code) {
        authFetch("/info/preview/" + code).then((res) => {
            res.json().then((json) => {
            this.form = json;
            $('.loading-overlay').removeClass('show');
            });
        });
    },
    },
}
</script>